import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String
  };
  connect() {
    console.log(this.urlValue);
    fetch("/issue_pass" + this.urlValue, {
      method: "POST"
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.downloadUrl);
        window.location.href = data.downloadUrl; // Redirect to the URL
      });
  }
}
